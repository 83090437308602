import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { BottomSheet } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "bottom-sheet"
    }}>{`Bottom Sheet`}</h1>
    <p>{`하단에 고정되어 있지만 누르면 Slide Up되는 컴포넌트입니다. 예제에선 fullScreen이 작동하지 않도록 해뒀습니다. 또한 아직 미완성이라 불안정한 면이 있습니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={BottomSheet} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<div\n  style={{\n    position: \'relative\',\n    top: 0,\n    left: 0,\n    width: \'100%\',\n    height: 200,\n    overflow: \'hidden\',\n    paddingLeft: 2,\n    paddingRight: 2,\n  }}\n>\n  <BottomSheet\n    fullScreen={false}\n    zIndex={2001}\n    title=\"Bottom Sheet\"\n    badgeCount={101}\n    renderFixedContent={() => <div>List</div>}\n    renderContent={() => (\n      <ul style={{ margin: 0 }}>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n        <li>Item</li>\n      </ul>\n    )}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BottomSheet,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: 200,
        overflow: 'hidden',
        paddingLeft: 2,
        paddingRight: 2
      }}>
    <BottomSheet fullScreen={false} zIndex={2001} title="Bottom Sheet" badgeCount={101} renderFixedContent={() => <div>List</div>} renderContent={() => <ul style={{
          margin: 0
        }}>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
          <li>Item</li>
        </ul>} mdxType="BottomSheet" />
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      